export default [
    {
        label: '순번',
        field: 'order_number',
        sort: true
    },
    {
        label: '제목',
        field: 'title',
        sort: true
    },
    {
        label: '작성자',
        field: 'writer',
        sort: true
    },
    {
        label: '이메일',
        field: 'email',
        sort: true,
    },
    {
        label: '등록일',
        field: 'reg_date',
        sort: true
    },

];